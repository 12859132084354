import React from 'react';
import { /*Link,*/ graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const pathUtil = require('../util/PathUtil');
// const DOMPurifier = require('../util/HTMLPurifier').domPurifierFactory();
const timeUtil = require('../util/TimeUtil');

export default function Template({ data }) {
  const post = data.markdownRemark;
  const baseUrl = process.env.GATSBY_BASE_URL;
  const path = pathUtil.GeneratePath(
    post.frontmatter.created,
    post.frontmatter.path_title,
    post.frontmatter.lan
  );
  const fullPath = `${baseUrl}/${path}`;
  const tags = post.frontmatter.tags !== null ? post.frontmatter.tags : [];
  return (
    <Layout>
      <SEO
        lang={post.frontmatter.lan}
        title={post.frontmatter.title}
        imgUrl={post.frontmatter.feat_img.childImageSharp.fluid.src}
        canUrl={fullPath}
        description={post.frontmatter.description}
      />
      <section id="article">
        <div className="container">
          <div className="page-container">
            <article className="card">
              {/* <Link to="/blog">
                <h3>
                  {process.env.GATSBY_LANGUAGE === 'th'
                    ? 'ย้อนกลับ'
                    : 'Go Back'}
                </h3>
              </Link> */}
              <hr />
              <h1 className="l-heading">{post.frontmatter.title}</h1>
              <div className="meta">
                <small>
                  <i className="fas fa-user"></i>
                  {process.env.GATSBY_LANGUAGE === 'th'
                    ? 'โพสโดย '
                    : 'Posted by'}
                  {post.frontmatter.author}
                  {', '}
                  {process.env.GATSBY_LANGUAGE === 'th'
                    ? timeUtil.UTCThTimeStr(post.frontmatter.created)
                    : timeUtil.UTCEnTimeStr(post.frontmatter.created)}
                </small>
                {tags.map(tag => {
                  let innerText;
                  if (tag === 'foodanddrink') {
                    innerText = 'Food & Drink';
                  } else {
                    innerText = tag;
                  }
                  return (
                    <span className={`category category-${tag}`}>
                      {innerText}
                    </span>
                  );
                })}
              </div>
              <div
                // dangerouslySetInnerHTML={{
                //   __html: DOMPurifier.sanitize(post.html, { ALLOWED_TAGS: ['iframe'] }),
                // }}
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
              <hr />
              {/* <Link to="/blog">
                <h3>
                  {process.env.GATSBY_LANGUAGE === 'th'
                    ? 'ย้อนกลับ'
                    : 'Go Back'}
                </h3>
              </Link> */}
            </article>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const postQuery = graphql`
  query BlogPostById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        path_title
        created
        expire
        title
        include
        tags
        title
        author
        lan
        description
        feat_img {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
